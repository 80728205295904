import React from "react";
import { Layout, Seo } from 'components';
import { Row, Col } from "react-grid-system";
import ProductHero from "components/product-hero";
import SlideContent from "components/slide-content";
import productData from "components/product-data";
import Slides from "components/product-data/slides";

import { TypeB } from "components/callouts";

import imgBivalirudin from "images/products/bivalirudin-box-and-vial.png"
import dexmedBoxImg from "images/home/dexmed-pole-image.png";
import ephedrineBoxImg from "images/home/ephedrine-sulfate-injection-bottle.svg";
import prevduoBoxImg from "images/home/prevduo-box-and-syringe.png";
import vasostrictBoxImg from "images/home/vasostrict-bottles.svg";

import './products.scss';

const Reference = (
    <>
        <p>
            <strong>References: 1.</strong> Bivalirudin Injection. Prescribing Information. Par Pharmaceutical, Inc. <strong>2.</strong> Healthcare Distribution Alliance. Standard pharmaceutical product and medical device information: Bivalirudin Injection. July 10, 2023. <strong>3.</strong> The United States Pharmacopeial Convention. USP compounding standards and beyond-use dates. https://go.usp.org/2022_Revisions_795_797. Accessed November 8, 2023. <strong>4.</strong> Data on file. DOF-EH-01. Par Pharmaceutical, Inc.; July 31, 2023. <strong>5.</strong> Data on file. DOF-PRVD-01. Par Pharmaceutical, Inc.; July 20, 2023. <strong>6.</strong> Data on file. DOF-DX-01. Par Pharmaceutical, Inc.; July 27, 2023. <strong>7.</strong> Data on file. DOF-VS-02. Par Pharmaceutical, Inc.; September 21, 2023.
        </p>
    </>
)

function BivalirudinPage({ pageContext }) {

    return (
        <Layout
            product="bivalirudin"
            pageClass="products page-bivalirudin"
            reference={Reference}
            breadcrumbData={pageContext.breadcrumb}
        >
            <Seo pageTitle="Bivalirudin Injection" pageDescription="Bivalirudin Injection ready-to-use bottles" />

            <section className="first">
                <div className="centered-content">
                    <ProductHero
                        productTitle="Bivalirudin Injection"
                        productSubTitle="Available in ready-to-use <br class='show-desktop' /> (RTU) bottles"
                        productUnitsOne="250 mg/50 mL (5 mg/mL)"
                        productImg={imgBivalirudin}
                        productImgAlt='Bivalirudin injection ready-to-use bottles'
                        className="prd-bivalirudin"
                    ></ProductHero>
                </div>
            </section>

            <section className="gray-bg">
                <div className="centered-content">
                    <Row align="start" justify="between">
                        <Col lg={5} xs={12}>
                            <SlideContent
                                subTitle="The benefits of RTU in the following <br class='show-desktop' /> concentration<sup>1</sup>"
                                details="Bivalirudin Injection RTU bottles do not require compounding, diluting, mixing, or transferring and provide extended dating compared with compounded products.<sup>1-3</sup>* Bivalirudin Injection RTU bottles are preservative-free and compatible with most automated dispensing machines." />
                        </Col>
                        <Col lg={6} xs={12}>
                            <Slides data={productData.bivalirudin} />
                        </Col>
                    </Row>
                </div>
            </section>

            <section className="links-row">
                <div className="centered-content">
                    <Row>
                        <Col xs={12}>
                            <a
                                href="https://d1skd172ik98el.cloudfront.net/48a33315-f594-4269-8043-8853d10fb7bf/98eb41b3-9ead-4f7a-9678-0a65032775c9/98eb41b3-9ead-4f7a-9678-0a65032775c9_source__v.pdf#page=2"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="external-link"
                                style={{ display: 'inline-block' }}>
                                Pharmacy Workflow Preparation
                            </a>


                            <p>
                                <a
                                    href="https://d1skd172ik98el.cloudfront.net/48a33315-f594-4269-8043-8853d10fb7bf/98eb41b3-9ead-4f7a-9678-0a65032775c9/98eb41b3-9ead-4f7a-9678-0a65032775c9_source__v.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="pdf-link">
                                    Bivalirudin Information Document
                                </a>
                            </p>
                        </Col>
                    </Row>
                </div>
            </section>

            <section className="cta-row">
                <div className="centered-content">
                    <div className="block-title">
                        <h3>Explore TruDelivery<sup>&reg;</sup> RTU portfolio</h3>
                    </div>

                    <Row justify="center">
                        <Col lg={4.25} md={6}>
                            <TypeB
                                className='dexmed-img'
                                ctaTitle="Dexmedetomidine HCl Injection"
                                ctaSubTitle="Ready-to-use bottles"
                                ctaImage={dexmedBoxImg}
                                ctaImageAlt='Dexmedetomidine HCI injection ready-to-use bottles>'
                                pdfLink='https://www.endodocuments.com/documents/Dexmedetomidine/Dexmedetomidine_PI.pdf'
                                buttonText="View Product"
                                buttonURL="/products/dexmedetomidine-hcl"
                                captionClass='img-caption__right'
                            />
                        </Col>

                        <Col lg={4.25} md={6}>
                            <TypeB
                                ctaTitle="Ephedrine Sulfate<br class='show-desktop' /> Injection"
                                ctaSubTitle="Premixed vials"
                                ctaImage={ephedrineBoxImg}
                                ctaImageAlt='Ephedrine sulfate injection premixed vials'
                                pdfLink='https://www.endodocuments.com/documents/Ephedrine/Ephedrine_5_PI.pdf'
                                buttonText="View Product"
                                buttonURL="/products/ephedrine-sulfate-injection"
                                captionClass='img-caption__right img-caption__rightAlt'
                            />
                        </Col>

                        <Col lg={4.25} md={6}>
                            <TypeB
                                ctaTitle="PREVDUO<sup>&reg;</sup> (neostigmine  methylsulfate and  glycopyrrolate) Injection"
                                ctaSubTitle="Prefilled syringes"
                                ctaImage={prevduoBoxImg}
                                ctaImageAlt='Prevduo carton and prefilled syringe'
                                pdfLink='https://www.parpharm.com/pdfs/catalog/sterile/PREVDUO_PI_02-2023.pdf'
                                buttonText="View Product"
                                buttonURL="/products/prevduo-injection"
                                captionClass='img-caption__right'
                                className='pb-lg-0'
                            />
                        </Col>

                        <Col lg={4.25} md={6}>
                            <TypeB
                                ctaTitle="Vasostrict<sup>&reg;</sup><br />(Vasopressin Injection, USP)"
                                ctaSubTitle="Ready-to-use bottles"
                                ctaImage={vasostrictBoxImg}
                                ctaImageAlt='Vasostrict ready-to-use bottles'
                                pdfLink='https://www.endodocuments.com/documents/Vasostrict/Vasostrict_PI.pdf'
                                buttonText="View Product"
                                buttonURL="/products/vasostrict-injection"
                                captionClass='img-caption__right'
                                className='pb-lg-0'
                            />
                        </Col>
                    </Row>
                </div>
            </section>

            <section className="block-footnotes">
                <div className="centered-content">
                    <div className="footnotes">
                        <div className="footnotes-wrap">
                            <span className='symbol'>*</span>
                            <p className="footnotes-symbols">For Category 3 controlled sterile preparations (CSP), the maximum beyond-use date (BUD) is 90 days at controlled room temperature (CRT), 120 days in refrigerator, and 180 days in freezer<sup>3</sup> versus shelf life of 2 years at CRT for Ephedrine, PREVDUO<sup>®</sup>, and Dexmedetomidine<sup>4-6</sup>; 12 months at CRT and 2 years in refrigerator for Vasostrict<sup>®7</sup>; 72 hours at CRT and 18 months in refrigerator for Bivalirudin.<sup>1,2</sup></p>
                        </div>
                    </div>
                </div>
            </section>

        </Layout>
    );
}

export default BivalirudinPage;